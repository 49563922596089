<template>
  <div id="app">
    <div class="p-4">
      <el-row :gutter="20" type="flex" justify="center" class="flex-wrap">
        <el-col :xs="24" :sm="7" :md="9" :lg="5" :xl="3">
          <div class="text-black bg-white mb-4">
            <a href="//enobj.cn" class="block logo"></a>
            <div class="apps p-4">
              <div class="app flex items-center cursor-pointer"
                :class="currentApp == app ? 'bg-gray-100' : 'hover:bg-gray-50'" @click="touchApp(app)"
                v-for="app in apps" v-show="!colleted || currentApp == app" :key="app.name">
                <img :src="require('@/assets/' + app.logo)" class="w-12 h-12 object-cover block p-1" />
                <div class="text-sm">
                  {{ app.name }}
                </div>
                <div v-show="colleted && currentApp == app" class="flex-auto text-right mr-4">
                  <i class="el-icon-more"></i>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="17" :md="15" :lg="12" :xl="8">
          <div class="main flex flex-col">
            <!-- <ColorCity /> -->
            <div v-if="currentApp.name == '爱追更'" class="bg-white p-4 flex-auto">
              <div class="my-4">
                <div>
                  <h1 class="text-lg font-bold">{{ currentApp.name }}</h1>
                  <p class="my-2">{{ currentApp.slogo }}</p>
                  <p class="my-4" v-if="currentApp.location">
                    访问地址：<el-link :href="currentApp.location" type="primary">{{ currentApp.location }}</el-link>
                  </p>
                </div>
              </div>
              <div class="app-imgs bg-gray-100">
                <el-carousel :interval="4000" height="500px">
                  <el-carousel-item v-for="image in currentApp.images" :key="image">
                    <el-image fit="contain" style="width:100%;height:100%;" :src="image">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div v-else-if="currentApp.name == '场宁Office'" class="bg-white p-4 flex-auto">
              <div class="my-4 flex justify-between items-start">
                <div>
                  <h1 class="text-lg font-bold">
                    {{ currentApp.name }}&nbsp;<el-tag size="small">小程序</el-tag>
                  </h1>
                  <p class="my-2">{{ currentApp.slogo }}</p>
                  <p class="my-4">
                    访问方式：微信搜索“场宁Office”
                  </p>
                </div>
                <div>
                  <el-image style="width: 100px" :fit="'cover'" :preview-src-list="[
        'https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E5%AE%A3%E4%BC%A0%E5%9B%BE/logo.png?sign=9a1455ff2fb215d909f070fbad98ec9f&t=1659774408',
      ]" :src="'https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E5%AE%A3%E4%BC%A0%E5%9B%BE/logo.png?sign=9a1455ff2fb215d909f070fbad98ec9f&t=1659774408'
        ">
                  </el-image>
                </div>
              </div>
              <div class="app-imgs bg-gray-100">
                <el-carousel :interval="4000" height="500px">
                  <el-carousel-item v-for="image in currentApp.images" :key="image">
                    <el-image fit="contain" style="width:100%;height:100%;" :src="image">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div v-else-if="currentApp.name == '变色龙'" class="bg-white p-4 flex-auto">
              <div class="my-8">
                <div>
                  <h1 class="text-lg font-bold">
                    {{ currentApp.name }}&nbsp;<el-tag size="small" type="warning">浏览器插件</el-tag>
                  </h1>
                  <p class="my-2">{{ currentApp.slogo }}</p>
                  <p class="my-4 text-sm">
                    <el-link target="_blank" type="primary" class="mr-4"
                      href="https://microsoftedge.microsoft.com/addons/detail/%E5%8F%98%E8%89%B2%E9%BE%99%E4%B8%80%E9%94%AE%E7%BB%99%E7%BD%91%E7%AB%99%E5%8F%98%E8%A3%85/afolkaeajjfnhjjpkmipjejnkfgfhoni">edge</el-link>
                    <el-link target="_blank" type="primary"
                      href="https://chrome.google.com/webstore/detail/%E5%8F%98%E8%89%B2%E9%BE%99-%E4%B8%80%E9%94%AE%E7%BB%99%E7%BD%91%E7%AB%99%E5%8F%98%E8%A3%85/fhfandefdeilokmhdebaiipcgmakpdpi">chrome</el-link>
                  </p>
                </div>
              </div>
              <div class="app-imgs ">
                <el-carousel :interval="4000" height="500px">
                  <el-carousel-item v-for="image in currentApp.images" :key="image">
                    <el-image fit="contain" style="width:100%;height:100%;"
                      :src="require('@/assets/bianselong/' + image)">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div v-else-if="currentApp.name == '易清理'" class="bg-white p-4 flex-auto">
              <div class="my-8">
                <div>
                  <h1 class="text-lg font-bold">
                    {{ currentApp.name }}&nbsp;<el-tag size="small" type="warning">浏览器插件</el-tag>
                  </h1>
                  <p class="my-2">{{ currentApp.slogo }}</p>
                  <p class="my-4 text-sm">
                    <el-link target="_blank" type="primary" class="mr-4"
                      href="https://microsoftedge.microsoft.com/addons/detail/%E6%98%93%E6%B8%85%E7%90%86%E5%8E%86%E5%8F%B2%E8%AE%B0%E5%BD%95%E6%B8%85%E7%90%86%E5%8A%A9%E6%89%8B/eopcflecanakcgccmoekgliphaoajbfo">edge</el-link>
                    <el-link target="_blank" type="primary"
                      href="https://chrome.google.com/webstore/detail/%E6%98%93%E6%B8%85%E7%90%86-%E5%8E%86%E5%8F%B2%E8%AE%B0%E5%BD%95%E6%B8%85%E7%90%86%E5%8A%A9%E6%89%8B/epchafhoogakfaclcocccmojkjogioee">chrome</el-link>
                  </p>
                </div>
              </div>
              <div class="app-imgs ">
                <el-carousel :interval="4000" height="500px">
                  <el-carousel-item v-for="image in currentApp.images" :key="image">
                    <el-image fit="contain" style="width:100%;height:100%;"
                      :src="require('@/assets/easy-history/' + image)">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div v-else-if="currentApp.name == '二维码卡片分享'" class="bg-white p-4 flex-auto">
              <div class="my-8">
                <div>
                  <h1 class="text-lg font-bold">
                    {{ currentApp.name }}&nbsp;<el-tag size="small" type="warning">浏览器插件</el-tag>
                  </h1>
                  <p class="my-2">{{ currentApp.slogo }}</p>
                  <p class="my-4 text-sm">
                    <el-link target="_blank" type="primary" class="mr-4"
                      href="https://microsoftedge.microsoft.com/addons/detail/%E4%BA%8C%E7%BB%B4%E7%A0%81%E5%8D%A1%E7%89%87%E5%88%86%E4%BA%AB/gnoikhkobjmbhjidjhdmaalilgmgdbbg?hl=zh-CN">edge</el-link>
                    <el-link target="_blank" type="primary"
                      href="https://chrome.google.com/webstore/detail/%E4%BA%8C%E7%BB%B4%E7%A0%81%E5%8D%A1%E7%89%87%E5%88%86%E4%BA%AB/oklofopfngdfdoillpphnhekgabpmcef">chrome</el-link>
                  </p>
                </div>
              </div>
              <div class="app-imgs ">
                <el-carousel :interval="4000" height="500px">
                  <el-carousel-item v-for="image in currentApp.images" :key="image">
                    <el-image fit="contain" style="width:100%;height:100%;" :src="require('@/assets/qrcode/' + image)">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="current-app flex-auto" v-else>
              <h1 class="text-lg font-bold my-4">{{ currentApp.name }}</h1>
              <p class="my-2">{{ currentApp.slogo }}</p>
              <p class="my-4" v-if="currentApp.location">
                访问地址：<el-link :href="currentApp.location" type="primary">{{
        currentApp.location
      }}</el-link>
              </p>
              <div class="app-imgs ">
                <el-carousel :interval="4000" height="500px">
                  <el-carousel-item v-for="image in currentApp.images" :key="image">
                    <el-image fit="contain" style="width:100%;height:100%;" :src="require('@/assets/' + image)">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="power-by not-importent">
      <el-row type="flex" justify="space-around" class="h-full">
        <el-col :xs="24" :sm="20" :md="16" :lg="12">
          <div class="flex justify-between items-center h-full">
            <div class="bm">
              <div class="bm-title">EnObj</div>
              <div class="bm-desc">
                奥利会的作品集
              </div>
            </div>
            <div class="bm-right">
              <div class="bm-desc text-right">
                <el-link href="https://beian.miit.gov.cn/" target="_blank" type="info">浙ICP备2024083780号-3</el-link>
              </div>
              <div class="flex">
                <img :src="require('@/assets/beian-gongan.png')" class="w-6 h-6 object-cover block p-1" />
                <el-link href="https://beian.mps.gov.cn/#/query/webSearch?code=33010802013576" rel="noreferrer"
                  target="_blank" type="info">浙公网安备33010802013576</el-link>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      bgPattern: "diagonal-stripes",
      bgSize: "sm",
      currentApp: {},
      colleted: false, // 是否折叠
      apps: [
        {
          name: "爱追更",
          // location: "/zhuigeng/index.html",
          slogo: "一个帮助你追踪爱豆最新动态的h5应用。",
          logo: "logo-zhuigeng.png",
          images: [
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/zhuigeng/h5/%E9%A6%96%E9%A1%B5.PNG?sign=e4660558b974f98379a5a675e87d2e43&t=1666145514",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/zhuigeng/h5/%E9%A2%91%E9%81%93%E9%A1%B5.PNG?sign=5185933a9e0d0e4a104bc273e3d816c6&t=1666145547",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/zhuigeng/h5/%E6%90%9C%E7%B4%A2.PNG?sign=d68e6a067bcca676ebfafb938a97fc03&t=1666145556",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/zhuigeng/h5/%E6%B6%88%E6%81%AF%E9%A1%B5.PNG?sign=e26a540a1b35eef4633ae27b4675bcd8&t=1666145567",
          ],
        },
        {
          name: "场宁Office",
          // location: "/",
          slogo: "一款用于提升移动办公处理效率的产品。",
          logo: "logo-office.png",
          images: [
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E6%96%B0%E7%89%88%E5%AE%A3%E4%BC%A0%E5%9B%BE/word%E6%96%87%E6%A1%A3.PNG?sign=a79d7427f39c718f5990e75f7ec66b5b&t=1666149282",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E6%96%B0%E7%89%88%E5%AE%A3%E4%BC%A0%E5%9B%BE/%E5%88%B6%E5%9B%BE.PNG?sign=93c49703aae03041d4f9eb5a14faff72&t=1666149350",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E6%96%B0%E7%89%88%E5%AE%A3%E4%BC%A0%E5%9B%BE/%E5%8E%BB%E6%B0%B4%E5%8D%B0.PNG?sign=fd9d197020f4c0437cab685161de90b4&t=1666149722",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E6%96%B0%E7%89%88%E5%AE%A3%E4%BC%A0%E5%9B%BE/%E8%BF%BD%E6%9B%B4.PNG?sign=7a0e404d628aa5522350308c6cefdc0f&t=1666149368",
            "https://6465-dev-9g0suwuw61afb9f3-1252108641.tcb.qcloud.la/office/%E6%96%B0%E7%89%88%E5%AE%A3%E4%BC%A0%E5%9B%BE/%E8%B5%84%E6%96%99%E5%BA%93.PNG?sign=7c4919a367d6515ae67abf4322e84f05&t=1666149374",
          ],
        },
        {
          name: "领客",
          // location: "/linker/index.html",
          slogo: "网站导航专家。",
          logo: "logo-linker.jpeg",
        },
        {
          name: "印象",
          // location: "/yinxiang/index.html",
          slogo: "来印象日历写日记，每一天都可以色彩斑斓。",
          logo: "logo-yinxiang.png",
        },
        {
          name: "阅读器",
          // location: "/reader/index.html",
          slogo: "去繁就简，回归2G复古wap风。",
          logo: "logo-reader.png",
        },
        {
          name: "变色龙",
          slogo: "为你喜爱的网站一键换装（浏览器插件）。",
          images: ["main.png"],
          logo: "logo-bianselong.png",
        },
        {
          name: "易清理",
          slogo: "协助按站点和时间周期清理浏览器历史记录（浏览器插件）。",
          images: ["main.png"],
          logo: "logo-easy-history.png",
        },
        {
          name: "二维码卡片分享",
          slogo: "帮助用户生成带二维码的网页分享卡片。",
          images: ["main1.png", "main2.png"],
          logo: "logo-qrcode-share.png",
        },
        {
          name: "web-art",
          // location: "/art/index.html",
          slogo: "使用web技术实现一些艺术效果。",
          logo: "logo.png",
        },
        {
          name: "快乐拷贝",
          // location:
          //   "https://pan.xunlei.com/s/VNEF8aPq5sdSFqArGPW4tDMeA1?pwd=u4zz#",
          slogo: "快速复制一段内容（常驻系统任务栏）。",
          images: ["happy-copy.jpg"],
          logo: "logo.png",
        },
        {
          name: "给赞",
          // location: "/geizan/index.html",
          slogo: "给那些互联网边缘的原创内容一个赞。",
          logo: "logo.png",
        },
        {
          name: "百词",
          // location: "/baici/index.html",
          slogo: "让背单词不再那么枯燥。",
          logo: "logo.png",
        },
        {
          name: "会员价",
          // location: "/vipp/index.html",
          slogo: "互联网内容价目表一览。",
          logo: "logo.png",
        },
        {
          name: "肥皂录",
          // location: "/feizao/index.html",
          slogo: "像肥皂剧一样不停释放内容的互联网频道。",
          logo: "logo.png",
        },
        {
          name: "假聊",
          // location: "/jialiao/index.html",
          slogo: "微信聊天风格的AI程序。",
          logo: "logo.png",
        },
        {
          name: "周店",
          // location: "/zhoudian/index.html",
          slogo: "周围都有哪些门店。",
          logo: "logo.png",
        },

        {
          name: "码住",
          // location: "/mazhu/index.html",
          slogo: "WEB代码片段工具。",
          logo: "logo.png",
        },
        {
          name: "上云",
          // location: "/shangyun/index.html",
          slogo: "托管UGC内容，以便随时随地访问。",
          logo: "logo.png",
        },
      ],
    }
  },
  created() {
    const loca = new URL(location)
    const appName = loca.searchParams.get("app")
    const app = this.apps.find((app) => app.name === appName)
    if (app) {
      this.currentApp = app
    } else {
      this.touchApp(this.apps[0])
    }
  },
  methods: {
    openApp(app) {
      window.location = app.location
    },
    touchApp(app) {
      if (this.currentApp == app) {
        this.colleted = !this.colleted
      } else {
        const newLoca = new URL(location)
        newLoca.searchParams.set("app", app.name)
        location.href = newLoca.toString()
      }
    },
  },
}
</script>

<style>
#app {
  margin: 0;
  width: 100%;
  background-color: #f6f6f6;
}

.main {
  min-height: calc(100vh - 200px - 2rem);
}

.current-app {
  padding: 15px;
  background: #fff;
  width: 100%;
}

.logo {
  font-weight: bold;
  width: 120px;
  height: 120px;
  margin: auto;
  background: url("./assets/logo.png") center / 150% auto no-repeat;
}

.power-by {
  text-align: center;
  background: #333;
  height: 200px;
}

.not-importent {
  color: gray;
  font-size: 14px;
}

.card {
  margin-bottom: 20px;
  flex: none;
}

.color-city-card {
  height: 100%;
}

.text-color {
  color: #eef1f5;
}

.bm {
  color: #ddd;
  text-align: left;
  padding: 15px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.bm-right {
  padding: 15px;
}

.bm-title {
  font-size: 20px;
  font-weight: 700;
}

.bm-desc {
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
}

.bg-mint {
  background-color: mediumseagreen;
}

.white {
  color: #fff;
}

.myapp {
  background: #8b57a7;
}
</style>
